import React from 'react';
import Navbar from './global-components/navbar-v3';
import BannerV2 from './section-components/banner-v2';
import ServiceV2 from './section-components/services-v2';
import BusinessManage from './section-components/business-manage';
import DataAnalytics from './section-components/data-analytics';
import BusinessSolution from './section-components/business-solution';
import PricingTable from './section-components/pricing-table-v2';
// import Subscribe from './section-components/subscribe';
import FooterV2 from './global-components/footer-v2';
import { Element } from 'react-scroll';

const Home_v3 = () => {
    return (
        <div>
            <Navbar />
            <Element name="banner">
                <BannerV2 />
            </Element>
            <Element name="services">
                <ServiceV2 />
            </Element>
            <Element name="business-manage">
                <BusinessManage />
            </Element>
            <Element name="data-analytics">
                <DataAnalytics />
            </Element>
            <Element name="business-solution">
                <BusinessSolution />
            </Element>
            <Element name="pricing">
                <PricingTable />
            </Element>
            {/* <Element name="subscribe">
                <Subscribe />
            </Element> */}
            <Element name="footer">
                <FooterV2 />
            </Element>
        </div>
    );
}

export default Home_v3;

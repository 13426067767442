import React, { Component } from 'react';
import { Link } from 'react-scroll';

class Navbar_v3 extends Component {

  render() {
    let publicUrl = process.env.PUBLIC_URL + '/'
    let imgattr = 'logo'
    let anchor = '#'


    return (
      <div>
        <nav className="navbar navbar-area navbar-area-2 navbar-expand-lg">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper mobile-logo">
                <a href={anchor} className="logo">
                  <img className="main-logo" src={publicUrl + 'assets/img/logo2.png'} alt={imgattr} />
                  <img className="sticky-logo" src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                </a>
              </div>
              <div className="nav-right-content">
                {/* <ul>
                  <li className="search">
                    <i className="ti-search" />
                  </li>
                  <li className="cart">
                    <div className="notification">
                      <a href="#">
                        <i className="la la-shopping-cart" />
                        <span className="notification-count">2</span>
                      </a>
                    </div>
                    <div className="widget_shopping_cart_content">
                      <ul>
                        <li>
                          <div className="media">
                            <div className="media-left">
                              <img src={publicUrl + "assets/img/checkout/7.png"} alt="img" />
                            </div>
                            <div className="media-body">
                              <a className="title" href="#">Smart watch red color</a>
                              <p>Quantity: 1</p>
                              <span className="price">$150.00</span>
                            </div>
                          </div>
                          <a className="remove-product" href="#"><span className="ti-close" /></a>
                        </li>
                        <li>
                          <div className="media">
                            <div className="media-left">
                              <img src={publicUrl + "assets/img/checkout/8.png"} alt="img" />
                            </div>
                            <div className="media-body">
                              <a className="title" href="#">Smart watch red color</a>
                              <p>Quantity: 1</p>
                              <span className="price">$150.00</span>
                            </div>
                          </div>
                          <a className="remove-product" href="#"><span className="ti-close" /></a>
                        </li>
                      </ul>
                      <p className="total">
                        <strong>Subtotal:</strong>
                        <span className="amount">
                          <span className="woocommerce-Price-currencySymbol">$</span>129.00
                        </span>
                      </p>
                      <p className="buttons">
                        <Link to="/checkout" className="button">View Card &amp; Check out</Link>
                      </p>
                    </div>
                  </li>
                  <li className="notification">
                    <a href="#">
                      <i className="fa fa-heart-o" />
                      <span className="notification-count">0</span>
                    </a>
                  </li>
                </ul> */}
              </div>
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#Riyaqas_main_menu"
                aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggle-icon">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
              <div className="logo-wrapper desktop-logo">
                <a href="#" className="logo">
                  <img className="main-logo" src={publicUrl + 'assets/img/logo2.png'} alt={imgattr} />
                  <img className="sticky-logo" src={publicUrl + 'assets/img/logo.png'} alt={imgattr} />
                </a>
              </div>
              <ul className="navbar-nav">
                <li className="menu-item-has-children">
                  <Link to="banner" smooth={true} duration={500}>
                    <a href="#banner" >Ana Sayfa</a>
                  </Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="services" smooth={true} duration={500}>
                    <a href="#services" >Özellikler</a>
                  </Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="pricing" smooth={true} duration={500}>
                    <a href="#pricing">Fiyatlandırma</a>
                  </Link>
                </li>
                <li className="menu-item-has-children">
                  <Link to="business-manage" smooth={true} duration={500}>
                    <a href="#business-manage">Neden YapıAsist ?</a>
                  </Link>
                </li>
                {/* <li className="menu-item-has-children">
                  <Link to="data-analytics" smooth={true} duration={500}>
                    <a href="#data-analytics">Career</a>
                  </Link>
                </li> */}
                <li>
                  <Link to="footer" smooth={true} duration={500}>
                    <a href="#footer">İletişim</a>
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div className="nav-right-content">
              <ul>
                <li className="search">
                  <i className="ti-search" />
                </li>
                <li className="cart">
                  <div className="notification">
                    <a href="#">
                      <i className="la la-shopping-cart" />
                      <span className="notification-count riyaqas-cart-count">2</span>
                    </a>
                  </div>
                  <div className="widget_shopping_cart_content">
                    <ul>
                      <li>
                        <div className="media">
                          <div className="media-left">
                            <img src={publicUrl + "assets/img/checkout/7.png"} alt="img" />
                          </div>
                          <div className="media-body">
                            <a className="title" href="#">Smart watch red color</a>
                            <p>Quantity: 1</p>
                            <span className="price">$150.00</span>
                          </div>
                        </div>
                        <a className="remove-product" href="#"><span className="ti-close" /></a>
                      </li>
                      <li>
                        <div className="media">
                          <div className="media-left">
                            <img src={publicUrl + "assets/img/checkout/8.png"} alt="img" />
                          </div>
                          <div className="media-body">
                            <a className="title" href="#">Smart watch red color</a>
                            <p>Quantity: 1</p>
                            <span className="price">$150.00</span>
                          </div>
                        </div>
                        <a className="remove-product" href="#"><span className="ti-close" /></a>
                      </li>
                    </ul>
                    <p className="total">
                      <strong>Subtotal:</strong>
                      <span className="amount">
                        <span className="woocommerce-Price-currencySymbol">$</span>129.00
                      </span>
                    </p>
                    <p className="buttons">
                      <Link to="/checkout" className="button">View Card &amp; Check out</Link>
                    </p>
                  </div>
                </li>
                <li className="notification">
                  <a href="#">
                    <i className="fa fa-heart-o" />
                    <span className="notification-count">0</span>
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
        </nav>

        <div className="body-overlay" id="body-overlay"></div>
        <div className="search-popup" id="search-popup">
          <form action="index.html" className="search-form">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Search....." />
            </div>
            <button type="submit" className="submit-btn"><i className="fa fa-search"></i></button>
          </form>
        </div>
      </div>
    )
  }
}


export default Navbar_v3